import request from '@/utils/request'

// 添加，编辑，删除 包接口
// http://api.dalongyun.com/project/239/interface/api/10217
export function updateApplication (data) {
  return request({
    url: '/other/saveAppOne',
    data
  })
}

// 获取列表，详情
// http://api.dalongyun.com/project/239/interface/api/10222
export function queryApplicationList (data = {}) {
  return request({
    url: '/other/getAppList',
    data
  })
}

// 已发布应用列表
// http://api.dalongyun.com/project/233/interface/api/14152
export function appList (data = {}) {
  return request({
    url: '/other/appList',
    data
  })
}

// 新应用发布
// http://api.dalongyun.com/project/233/interface/api/14166
export function addApp (data = {}) {
  return request({
    url: '/other/addApp',
    data
  })
}

// 已发布应用更新
// http://api.dalongyun.com/project/233/interface/api/14173
export function editApp (data = {}) {
  return request({
    url: '/other/editApp',
    data
  })
}

// 已发布应用删除
// http://api.dalongyun.com/project/233/interface/api/14180
export function delApp (data = {}) {
  return request({
    url: '/other/delApp',
    data
  })
}

// 已发布应用包版本列表
// http://api.dalongyun.com/project/233/interface/api/14159
export function appVersions (data = {}) {
  return request({
    url: '/other/appVersions',
    data
  })
}

// 已发布应用包版本列表
// http://api.dalongyun.com/project/233/interface/api/14187
export function appIds (data = {}) {
  return request({
    url: '/other/appIds',
    data
  })
}

// 已发布应用包版本删除
// http://api.dalongyun.com/project/233/interface/api/14201
export function delAppVersion (data = {}) {
  return request({
    url: '/other/delAppVersion',
    data
  })
}

// 已发布应用包版本添加
// http://api.dalongyun.com/project/233/interface/api/14187
export function addAppVersions (data = {}) {
  return request({
    url: '/other/addAppVersion',
    data
  })
}

// 已发布应用包版本编辑
// http://api.dalongyun.com/project/233/interface/api/14194
export function editAppVersion (data = {}) {
  return request({
    url: '/other/editAppVersion',
    data
  })
}
