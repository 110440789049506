<template>
  <Layout class="packageManagement">
    <h3 id="packageUpdate">
      应用包更新
    </h3>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="140px"
      label-position="right"
      style="width: 520px;"
    >
      <el-form-item
        label="选择应用"
        prop="appId"
        :error="asyncMssages.appId"
      >
        <el-select
          v-model="formData.appId"
          placeholder="请选择应用"
          :disabled="isEdit"
          clearable
          style="width: 220px;"
        >
          <el-option
            v-for="item in appIdsList"
            :key="item.appId"
            :label="item.name"
            :value="item.appId"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="版本号"
        prop="version"
        :error="asyncMssages.version"
      >
        <el-input
          v-model.number="formData.version"
          style="width: 220px;"
          placeholder="例如1.6.0.1版本, 则输入1601"
        />
      </el-form-item>

      <el-form-item
        label="提测日期"
        prop="measureDate"
        :error="asyncMssages.measureDate"
      >
        <el-date-picker
          v-model="formData.measureDate"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="提测日期"
        />
      </el-form-item>

      <el-form-item
        label="发版日期"
        prop="distributionDate"
        :error="asyncMssages.distributionDate"
      >
        <el-date-picker
          v-model="formData.distributionDate"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="发版日期"
        />
      </el-form-item>

      <el-form-item v-show="isAndroid">
        <template>
          <el-upload
            ref="upload"
            :action="UPLOAD_URL"
            :file-list="fileList"
            :before-upload="handleBeforeUpload"
            :on-remove="handleFileRemove"
            :on-success="handleSuccessUpload"
          >
            <el-button
              size="small"
              type="primary"
            >
              点击上传Android包文件
            </el-button>
          </el-upload>
        </template>
      </el-form-item>

      <el-form-item
        label="下载地址"
        prop="downloadUrl"
        :error="asyncMssages.downloadUrl"
      >
        <el-input
          v-model="formData.downloadUrl"
          :title="formData.downloadUrl"
          :disabled="isAndroid"
          placeholder="下载地址"
        />
      </el-form-item>

      <el-form-item
        label="更新内容"
        prop="log"
        :error="asyncMssages.log"
      >
        <el-input
          v-model="formData.log"
          type="textarea"
          :autosize="{ minRows: 4 }"
          placeholder="1. 修复了一些已知的方式"
          clearable
        />
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          :loading="isReleaseing"
          @click="handleSubmit"
        >
          确定发布
        </el-button>
        <el-button @click="handleReset">
          清除当前内容
        </el-button>
      </el-form-item>
    </el-form>

    <h3>历史版本</h3>
    <el-form
      :model="searchForm"
      label-position="right"
      style="width: 520px;"
    >
      <el-form-item>
        <el-select
          v-model="searchForm.appId"
          placeholder="请选择应用"
          clearable
          @change="queryAppVersions"
        >
          <el-option
            v-for="item in appIdsList"
            :key="item.appId"
            :label="item.name"
            :value="item.appId"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <el-table
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="appId"
        label="应用名"
        :formatter="appNameFormatter"
        min-width="100px"
      />
      <el-table-column
        prop="packageName"
        label="包名"
        min-width="250px"
      />
      <el-table-column
        prop="version"
        label="版本号"
        min-width="80px"
      />
      <el-table-column
        prop="measureDate"
        label="提测日期"
        min-width="100px"
      />
      <el-table-column
        prop="distributionDate"
        label="发版日期"
        min-width="100px"
      />
      <el-table-column
        fixed="right"
        width="240"
        label="操作"
      >
        <template v-slot="{row}">
          <a
            href="#packageUpdate"
            class="anchor-link"
          >
            <el-button
              type="primary"
              @click="handleEditRow(row)"
            >
              编辑
            </el-button>
          </a>
          <el-button
            type="warn"
            :loading="row.delLoading"
            @click="handleDelRow(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="searchForm.pageNum"
      :limit.sync="searchForm.pageSize"
      @pagination="queryAppVersions"
    />
  </Layout>
</template>

<script>
import NProgress from 'nprogress'
import { cloneDeep } from 'lodash'
import {
  appVersions,
  appIds,
  delAppVersion,
  addAppVersions,
  editAppVersion
} from '@/api/package.js'

let errorIndex = 1
export default {
  name: 'ApplicationPackage',
  data () {
    const isInteger = (rule, value, callback) => {
      if (!Number.isInteger(value)) {
        callback(new Error('该项必须是整数'))
      } else {
        callback()
      }
    }

    return {
      UPLOAD_URL: process.env.VUE_APP_BASEURL + process.env.VUE_APP_UPLOAD_URL,
      fileList: [],
      formData: {
        appId: '',
        version: '',
        measureDate: '',
        distributionDate: '',
        downloadUrl: '',
        log: ''
      },
      asyncMssages: {
        appId: '',
        version: '',
        measureDate: '',
        distributionDate: '',
        downloadUrl: '',
        log: ''
      },
      appIdsList: [],
      appIdsMap: {},
      isReleaseing: false,
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      isEdit: false,
      searchForm: {
        appId: '',
        pageNum: 1,
        pageSize: 20
      },
      rules: {
        appId: [{ required: true, message: '必须选择应用' }],
        version: [
          { required: true, message: '必须填写版本号' },
          { validator: isInteger }
        ],
        measureDate: [{ required: true, message: '必须选择提测日期' }],
        distributionDate: [{ required: true, message: '必须选择发版日期' }],
        log: [{ required: true, message: '必须填写更新内容' }]
      }
    }
  },
  computed: {
    isAndroid () {
      return this.appIdsList.filter(item => item.appId === this.formData.appId)?.[0]?.appPlatform === 'android'
    }
  },
  created () {
    this.queryAppVersions()
    this.queryAppIds()
  },
  methods: {
    appNameFormatter (row, column, cellValue, index) {
      return this.appIdsMap[cellValue] || '-'
    },
    handleSubmit () {
      this.isReleaseing = true
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm('确定发布?', '提示', { type: 'warning ' })
            .then(() => {
              if (!this.isEdit) {
                this.handleAddAppVersions()
              } else {
                this.handleEditAppVersion()
              }
            })
            .catch(() => {
              this.isReleaseing = false
            })
        } else {
          this.isReleaseing = false
        }
      })
    },
    handleAddAppVersions () {
      addAppVersions(this.formData).then(res => {
        if (res.code === 200) {
          if (res.data.success) {
            this.$message.success('发布成功')
            this.queryAppVersions()
            this.handleReset()
          } else {
            this.$message.warning(res.data.msg)
            if (res.data.field) {
              this.asyncMssages[res.data.field] = `Error_${errorIndex++}: ${res.data.msg}`
            }
          }
        }
      })
        .finally(() => {
          this.isReleaseing = false
        })
    },
    handleEditAppVersion () {
      const {
        id,
        appId,
        version,
        measureDate,
        distributionDate,
        downloadUrl,
        log
      } = this.formData
      editAppVersion({
        id,
        appId,
        version,
        measureDate,
        distributionDate,
        downloadUrl,
        log
      }).then(res => {
        if (res.code === 200) {
          if (res.data.success) {
            this.$message.success('更新成功')
            this.queryAppVersions()
            this.handleReset()
          } else {
            this.$message.warning(res.data.msg)
            if (res.data.field) {
              this.asyncMssages[res.data.field] = `Error_${errorIndex++}: ${res.data.msg}`
            }
          }
        }
      })
        .finally(() => {
          this.isReleaseing = false
        })
    },
    handleReset () {
      this.formData = {
        appId: '',
        version: '',
        measureDate: '',
        distributionDate: '',
        downloadUrl: '',
        log: ''
      }
      this.isEdit = false
      this.fileList = []
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    handleBeforeUpload (file) {
      NProgress.start()
    },
    handleFileRemove () {
      this.formData.downloadUrl = ''
    },
    handleSuccessUpload (res, file, fileList) {
      NProgress.done()
      this.formData.downloadUrl = res.data.url
      if (fileList.length > 1) {
        fileList.shift()
      }
    },
    queryAppVersions () {
      appVersions(this.searchForm)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data?.list || []
            this.listInfo.total = res.data?.total || 0
          }
        })
    },
    queryAppIds () {
      appIds()
        .then(res => {
          if (res.code === 200) {
            this.appIdsList = res?.data?.list || []
            this.appIdsMap = this.appIdsList.reduce((result, item) => {
              result[item.appId] = item.name
              return result
            }, {})
          }
        })
    },
    handleEditRow (row) {
      this.formData = cloneDeep(row)
      this.isEdit = true
    },
    handleDelRow (row) {
      this.$confirm('确定删除吗', '提示', { type: 'warning ' })
        .then(() => {
          this.$set(row, 'delLoading', true)
          delAppVersion({ id: row.id }).then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.queryAppVersions()
            }
          })
            .finally(() => {
              this.$set(row, 'delLoading', false)
            })
        })
    }
  }
}
</script>

<style>
  .anchor-link {
    display: inline;
    margin-right: 20px;
}
</style>
